import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c83d391a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

import { useRoute } from "vue-router";

export default {
  setup(__props) {

const id = useRoute().query.id
const type = useRoute().query.type

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("img", {
    src: require('@/assets/state/'+(_unref(type)==2?'app':'web')+_unref(id)+'.jpg'),
    alt: "",
    class: "web_img"
  }, null, 8, _hoisted_1))
}
}

}